

.header-browse-data{
    height: 58px;
    /* height: fit-content; */
    margin-bottom: 15px ;
    padding: 10px;
    background-color: var(--fill_2);
    display: table;
}
.main-area{
    padding: 0px;
}
.center-subtitle {
    line-height: 100%;
    padding: 10px 0px;
}
.daterangecol {
    width: 315px;
}
.selectdevicecol {
    width: 250px;
}
.btn-retrieve-data{
    margin: 3px 0px 0px 20px;
}
.main-area-tab{
    padding-top : 13px;
    /* padding-left : 8px; */
    margin-bottom : 10px;
    width : 80px;
    margin-left : 5px;
    margin-right : 5px;
    font-size:15px;
    font-weight: normal;
    text-align: center;
}

.main-area-tab-selected,.main-area-tab:hover{
    font-weight: bold;
    color: var(--wintics_yellow);
    padding-top : 9px;
    border-top : 4px solid var(--wintics_yellow) !important;
    cursor: pointer;
}

.main-area-tab-disabled{
    cursor: not-allowed !important
}



.zone-image{
    margin-top:10px !important;
    height:330px;
    padding: 15px !important;
    border-radius:6px;
    margin-left: 0px !important;
}
.btn-excel{
    /* margin-left:180px; */
    margin-bottom:8px;
}
.row-stats{
    display:flex;
    justify-content: space-between;
}
.zones-row{
    margin-top: 8px;
}
.zone-in-text{
    margin-left: 10px;
    /* color:rgb(0, 26, 255) ; */
}
.zone-out-text{
    margin-left: 10px;

    color: white;
}
.zone-in-square{
    margin-left: 0px;
    color: var(--wintics_blue);
}
.zone-out-square{
    margin-left: 40px;
    color: var(--wintics_yellow)
}
.block-fit-content{
    min-height: 90vh;
/*     height: fit-content
 */
    display: table;
}

.carousel:hover .slide .legend {
    opacity : 0.7 !important;
}

.carousel .thumb {
    height: 80px !important;
    width: 160px !important;
    border: 3px solid #333 !important;
}
.carousel .thumbs {
    text-align: center;
    width: 100%;
    overflow-x: scroll;
}

.carousel .thumb img {
    height: 80px !important;
    width: 160px !important;
}


.carousel .thumb.selected {
    border: 3px solid var(--fill_4) !important;
}

.carousel .thumb:hover {
    cursor: pointer;
}

.DateRangePickerInput{
    border-radius: 3px;
    background-color: var(--fill_3) !important;
}
.DateInput {
    background : var(--fill_3) !important;
}
.DateInput_input{
    color : white !important;
    background-color : var(--fill_3) !important;
    padding:  1px 0px !important;
    border-bottom : none !important;
    line-height: 100% !important;
    padding: 0px !important;;
}
.CalendarMonthGrid{
    background : var(--fill_3) !important;
}
.CalendarMonth{
    color : white ! important;
    background : var(--fill_3) !important;
}
.CalendarMonth_caption{
    color : white !important;
}
.CalendarDay__default{
    color : white ! important;
    background : var(--fill_3) !important;
}
.CalendarDay__default:hover{
    color : white ! important;
    background : rgb(136, 136, 136) !important;
}
.CalendarDay__blocked_out_of_range:hover{
    color : white ! important;
    background : rgb(85, 85, 85) !important;
    cursor: not-allowed !important;
}
.CalendarDay__blocked_out_of_range{
    color : white ! important;
    background : rgb(85, 85, 85) !important;
    cursor: not-allowed !important;
}
.CalendarDay__selected, .CalendarDay__hovered{
    color : #001b19 !important;
    background: rgb(255, 196, 0) !important;
    border-color: var(--wintics_yellow_darker_2) !important;

}
.CalendarDay__selected_span, .CalendarDay__hovered_span{
    color : #001b19 !important;
    background: var(--wintics_yellow) !important;
    border-color: var(--wintics_yellow_darker_2) !important;
}
.CalendarDay__selected_span:hover{
    color : #001b19 !important;
    background: rgb(255, 196, 0) !important;
}
.CalendarDay__selected_start:hover{

    color : #001b19 !important;
    background: var(--wintics_yellow_darker_1) !important;
}
.CalendarDay__selected_end:hover{

    color : #001b19 !important;
    background: var(--wintics_yellow_darker_1) !important;
}
.DayPickerNavigation_button__default{
    border: none;
    color : white !important;
    background: var(--fill_3) !important;
}
.DayPickerNavigation_svg__horizontal{
    fill : white!important;
}
.DateRangePickerInput_arrow_svg{
    fill : white!important;
    margin-right: 22px;
}
.DayPicker_focusRegion{
    background-color : var(--fill_3) ! important;
}
.DayPicker_weekHeader{
    color : white ! important;
}