@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.App {
  text-align: center;
}

body {
  background-color:var(--fill_3) !important;
  color:white;
  font-size:14px;
  overflow: hidden;
  font-family: 'Muli', sans-serif !important;
}
._loading_overlay_overlay{
  background-color: rgba(0, 0, 0, 0) !important;
}
.logo {
  height: 30px;
  vertical-align: middle;
  /* margin-bottom: 0.5em; */
  /* margin-left: -10vw; */
  margin:0px;
}

.checkbox-params{
  height: 1em;
}

.margin-bottom {
  margin-bottom:5px;
}
.switch-text {
  line-height:28px;
}
.btn-yellow {
  background-color: var(--wintics_yellow);
  border : 0;
  color: var(--wintics_font_black);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: bold;
  font-family: 'Muli', sans-serif !important;
}
.btn-round {
  border-radius: 50%;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: smaller;
  }
.btn-delete{
  background-color: var(--wintics_red) !important;
  color: white !important;
}
.recordingslist {
  background-color: var(--fill_2);
  /* padding: 20px; */
}

.btn-cancel{
  background-color: var(--wintics_red) !important;
  color: white !important;
  border : 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: bold;
}
.btn-basic{
  background-color: var(--fill_4) !important;
  color: white !important;
  border : 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: bold;
}
.overflow-y-scroll{
  overflow-y: scroll;
}
.btn-cancel:disabled,
.btn-cancel:visited,
.btn-cancel:focus:active,
.btn-cancel:active,
.btn-cancel:hover,
.btn-cancel:valid,
.btn-cancel:focus {
  background-color: var(--wintics_red_darker_1) !important;
  color: white !important;
  border : none !important;
  box-shadow: none !important;
}
.btn-basic:visited,
.btn-basic:focus:active,
.btn-basic:active,
.btn-basic:hover,
.btn-basic:valid,
.btn-basic:focus {
  background-color: var(--fill_3) !important;
  color: white !important;
  border : none !important;
  box-shadow: none !important;
}

.btn-confirm,
.btn-confirm:focus{
  background-color: var(--wintics_azure);
  border : 0;
  color: var(--wintics_font_black);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: bold;
  box-shadow: none !important;
  box-shadow: none !important;
}
.btn-confirm:visited,
.btn-confirm:focus:active,
.btn-confirm:active,
.btn-confirm:hover,
.btn-confirm:valid {
  background-color: var(--wintics_azure_darker_1) !important;
  color: black !important;
  border : none !important;
  box-shadow: none !important;
}

.btn-show{
margin-top:5px;
margin-bottom: 10px;
margin-left:2px;
width:auto
}

.btn-blue{
  background-color: var(--wintics_blue) !important;
  color: white !important;
  border : 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-weight: bold;
}
.btn-blue:visited,
.btn-blue:focus:active,
.btn-blue:active,
.btn-blue:hover,
.btn-blue:valid,
.btn-blue:focus {
  background-color: var(--wintics_blue_darker_1) !important;
  color: white !important;
  border : none !important;
  box-shadow: none !important;
}
.btn-yellow:disabled {
 cursor: not-allowed;
}
.btn-yellow:disabled,
.btn-yellow:visited,
.btn-yellow:focus:active,
.btn-yellow:active,
.btn-yellow:hover,
.btn-yellow:valid,
.btn-yellow:focus {
  background-color: var(--wintics_yellow_darker_1) !important;
  color: var(--wintics_font_black) !important;
  border : none !important;
  box-shadow: none !important;
}

.dark-input:active,
.dark-input:focus,
.dark-input:focus:active,
.dark-input:valid,
.dark-input {
  background-color: var(--fill_3) !important;
  color: white;
  border: 0;
}
.dark-input:hover {
  color: rgb(233, 233, 233);
}
.dark-input:checked {
  color: white;
}

.dark-input:-webkit-autofill,
.dark-input:-webkit-autofill:hover,
.dark-input:-webkit-autofill:focus{
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px var(--fill_3) inset;
  box-shadow: 0 0 0px 1000px var(--fill_3) inset;
  transition: background-color 5000s ease-in-out 0s;
}
.change-password-link{
  color:var(--wintics_yellow);
  cursor: pointer;

}

.select-wintics{
  width:100%;
  color:white;
}
.select-platform{
  width:170px;
  /* height: 80%; */
  color:white !important;
}

/*
.navbar-nav {
  max-height: 10vh;
} */
.buttonless {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.buttonless:hover,
.buttonless:active,
.buttonless:visited,
.buttonless:focus {
  background: none;
  border: none !important;
  outline: none !important;
  box-shadow:none !important;
  background-color:none !important;
}

.buttonless:focus {
  outline: none;
  outline-offset: none;
}

.buttonless:active {
  border: none;
  transform: scale(0.99);
}

.padding-lr-02rem{
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.navbar-brand{
  margin:0px;
  padding: 0;
}

.disabled-a {
  opacity: 0.3;
  cursor: default;
}
.disable-scrollbars {
  overflow: auto;
  max-height: 100%;
  height: auto !important;
}
.overflow-scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
}

.navbar {
  background-color: var(--fill_1) !important;
  font-weight: bold;
  font-size: 20px;
  /* max-height: 10vh; */
  padding-top: 0vh;
  /* padding-bottom: 0vh; */
  /* overflow: auto; */
}
 .navbar .nav-link:not(.disabled) {
  color: var(--wintics_font_white);
  margin-bottom: 0px;
  padding-bottom: 0rem;
}

.dropdown-menu{
  background-color: var(--fill_1);
}

.dropdown-item{
  color: white;
}

.nav-link {
  margin-left:0;
}


.dropdown-item:hover{
  background-color: var(--fill_2);
  color: white;
}
.dropdown-item.active{
  background-color: var(--fill_2);
  color: white;
}
.user-name{
  margin-right: 0;
}

.user-name:hover{
  box-shadow: none !important;
  color: white !important;
}

.text-bold{
  font-weight: bold;
}

.text-large,
label{
  font-size: 18px;
  margin-left:0;
}

.checkboxCustom{
  margin-left : 15px;
  margin-right : 2px;
}
.checkboxCustom input {
  display: none;
}
.input-period {
  width : 43px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.navbar .navbar-text {
  color: var(--wintics_font_white);
  /* margin-top: 1rem; */
  margin-top: 0px;
  /* margin-right: 20px; */
}
.margin-left-div{
  margin-left:10%;
}
.nav-link{
  margin-left : 2em;
}

.nav-link-active {
  box-shadow: 0px 0.4em 0px 0px var(--wintics_yellow)
}
.nav-link:focus{
  color: white !important;
}
.nav-link:hover {
  box-shadow: 0px 0.4em 0px 0px var(--wintics_yellow_darker_1);
  color: white !important;
}

.navbarContent {
  max-width: 100% ;
}

.card-dark {
  background-color: var(--fill_2);
}
.card-gray {
  padding:3px !important ;
  margin:3px 6px 3px 3px !important;
  background-color: var(--fill_3);
}
.card-wintics{
  border:none;
}
.card-wintics-developer{
  margin-top:5px;
  /* border-top: 2px solid var(--fill_4); */
}
.card-header-wintics{
  background-color:var(--fill_3)
}

.card-body-wintics{
  background-color:var(--fill_2);
  padding-left : 2px;
  padding-right:2px;
}
.row-info{
  height: 40px;
}
.deco-none {
  /* color: inherit; */
  text-decoration: inherit;
}

.deco-none:link {
  /* color: inherit; */
  text-decoration: inherit;
}

.deco-none:hover {
  /* color: inherit; */
  text-decoration: inherit;
}


.modal-wintics .modal-header,.modal-wintics .modal-body{
  color:white;
  background-color:var(--fill_3);
}
.modal-content {
  border: none;
}
.modal-wintics .close{
  color:white;
}
.modal-wintics .modal-header{
  background-color:var(--fill_2);
  border-bottom: 2px solid rgba(126, 126, 126, 0.356);
}
.modal-wintics .modal-footer{
  background-color:var(--fill_2);
  border-top: 2px solid rgba(126, 126, 126, 0.356);
}
.modal-wintics .modal-title{
  font-size:1.2rem !important;
}
.graph-icon{
  font-size: 100px;
  color: white;
}
.login-buttons{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin: auto;
}
.register-button{
  margin: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: gray !important;
}
.floatActiveIcon {
  float:right;
}
.accordion-title.collapsed:after {
    transform: rotate(0deg) ;
  }
.accordion-title{
  color:white !important;
}

:root {
  --fill_1: rgb(15, 16, 18);
  --fill_2: rgb(46, 49, 54);
  --fill_3: rgb(61, 66, 70);
  --fill_4: rgb(127, 127, 127);

  --wintics_yellow: rgb(252, 216, 5);
  --wintics_yellow_darker_1: rgb(230, 177, 5);
  --wintics_yellow_darker_2: rgb(196, 151, 3);
  --wintics_purple: rgb(112, 48, 160);
  --wintics_purple_darker_1: rgb(112, 48, 160);
  --wintics_purple_darker_2: rgb(112, 48, 160);
  --wintics_blue : #1508f8;
  --wintics_blue_darker_1 : rgb(11, 4, 158);
  --wintics_blue_darker_2 : rgb(5, 0, 109);
  --wintics_azure: rgb(0, 255, 180);
  --wintics_azure_darker_1: rgb(1, 201, 141);
  --wintics_azure_darker_2: rgb(1, 158, 111);
  --wintics_red : rgb(242,84,91);
  --wintics_red_darker_1 : rgb(212, 72, 79);
  --wintics_red_darker_2 : rgb(189, 65, 71);
  --precipitations_color : rgb(124, 251, 255);
  --precipitations_color_dim : rgba(124, 251, 255, 0.322);

  --wintics_font_black : var(--fill_2);
  --wintics_font_gray : var(--fill_4);
  --wintics_font_white : white;
  --wintics_font_yellow : var(--wintics_font_yellow);
}

.blockcol {
  padding-left: 5px;
  padding-right: 5px;
}

.block {
  background-color:var(--fill_2);
  padding: 20px;
  height: 90vh;
}
.form-group .row {
  margin-left: 0px;
  margin-right: 0px;
}
.block .row {
  margin-left: 0px;
  margin-right: 0px;
}
.center-small-text{
  padding-top:8px;
  padding-left:8px;
  line-height: 16px;
  font-size: 16px;
  color: var(--wintics_font_white);
}
.title {
  padding-bottom: 10px;
  margin-left: 0px;
  line-height: 18px;
  font-size: 18px;
  color: var(--wintics_font_white);
  font-weight: bold;
}
.title-yellow {
  color: var(--wintics_yellow)
}
.title-zones {
  padding-top : 37px;
}
.subtitle {
  padding-bottom: 10px;
  margin-left: 18px;
  font-size: 18px;
  color: var(--wintics_font_white);
}

.trackBarDiv {
  border-radius:7px;
  height: 300px;
  background-color: var(--fill_3)
}
.trackBarAvailabilityDiv {
  border-radius:7px;
  height: 320px;
  background-color: var(--fill_3)
}

.table-wintics {
  border-radius:7px;
  color: white !important;
  background-color: var(--fill_3);
  border : 0px !important;
}
.table-wintics tr:hover,
.table-wintics td:hover,
.table-wintics tbody:hover,
.table-wintics tbody {
  color: currentColor !important;
}
.table-wintics thead {
  color: var(--wintics_yellow) !important;
  border : 0px !important;
}

.table-wintics table  {
  color: white !important;
  border : 0px !important;
}
.table-wintics  tbody  {
  color: white !important;
  border : 0px !important;
}
.table-wintics td  {
  color: white !important;
  border : 0px !important;
  text-align : center;
}
.table-wintics  tr {
  color: white !important;
  border : 0px !important;
}

.table-wintics  th {
  color: var(--wintics_yellow) !important;
  border : 0px !important;
  text-align : center;
}

.react-json-view {
  word-break: break-all;
  border-radius: 7px;
  }

.testvideo .video-react-video {
  pointer-events: none;
}

.task-view-content{
  padding-left: 7px;
}
.task-view{
  cursor: pointer;
  /* border-left : 8px solid var(--wintics_yellow) transparent !important */
}
.task-view:hover{
  border-left : 8px solid var(--wintics_yellow) !important
}
.scale-on-hover:hover{
  transform: scale(1.2);
}
/* PARAMETERS */

.parameters {
  height: 100%;
}
.left-pane {
  width: 100%;
  max-width: 100% ;
  overflow-y: scroll
}

.fixed-overflow {
  height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.btn-logout{
  padding-left: 1em;
  padding-right: 1em;
  /* margin-left: 2.5em;
  margin-top: 0.5em; */
}

.control-btn {
  margin-right: 25px;
  margin-bottom: 10px;
  border-radius:11px;
  font-size:18px;
  color: var(--wintics_yellow);
  border-color: var(--wintics_yellow);
  /* background-color: var(--wintics_yellow); */
}

.control-btn.active {
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius:11px;
  font-size:18px;

  color: var(--wintics_font_black) !important;
  background-color: var(--wintics_yellow) !important ;
  border-color: var(--wintics_yellow) !important;
  /* background-color: var(--wintics_yellow); */
}
.arrow {
  margin-top:1rem;
  border: solid var(--wintics_font_gray);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 9px;
}

.right {
  transform: rotate(-45deg) ;
  -webkit-transform: rotate(-45deg);
}
.preview-scroll {
  /* overflow-y: scroll; */
  height: 100%;
  max-height: 100%;
}
.overflow-hidden {
  overflow: hidden;
}
.parameter-form {
  /* overflow-y: scroll; */
  height: 100%;
  color: var(--wintics_font_white)
}

.preview-tab {
  /* overflow-y: scroll; */
  color: var(--wintics_font_white);
  max-width: 100%;
}

.parameter-form  input {
  background-color: var(--fill_3);
  color: var(--wintics_font_white);
  border : none;
}

.parameter-form  select {
  background-color: var(--fill_3);
  color: var(--wintics_font_white);
  border : none;
}

.run-task-btn {
  font-size:18px;
  color: var(--wintics_font_black) !important;
  background-color: var(--wintics_yellow) !important ;
  border-color: var(--wintics_yellow) !important;
  position: absolute;
  bottom: 0;
}
.text-small {
  font-size: 16px;
}

.text-smaller {
  font-size: 14px;
}
.table-areas-title{
  font-size: 14px;
  font-weight: bold;
}
.checked-category {
  color: var(--wintics_yellow)
}

.unchecked-category {
  color: var(--fill_2);
  border-radius: 50%;
  border: 1px solid var(--wintics_yellow)
}

/* Zones styles */

.add-btn {
  margin-top : 7px;
  margin-bottom : 7px;
  border: 0px;
  padding : 5px 9px;
  font-size: small;
  font-weight: bold;
}

.add-btn:hover,
.add-btn:focus,
.add-btn:active{
  border: none;
  overflow: none;
}
.add-outflow:hover,
.add-outflow:focus
{
  background-color : var(--wintics_yellow_darker_1) !important;
  border : none !important;
  box-shadow: none !important;
  color: black !important;

}
.add-outflow:active
{
  background-color : var(--wintics_yellow_darker_2) !important;
  border : none !important;
  box-shadow: none !important;
  color: black !important;

}
.add-inflow:hover,
.add-inflow:focus
{
  background-color : var(--wintics_blue_darker_1) !important;
  border : none !important;
  box-shadow: none !important;
  color: white !important;

}
.add-inflow:active
{
  background-color : var(--wintics_blue_darker_2) !important;
  border : none !important;
  box-shadow: none !important;
  color: white !important;

}

.add-outflow {
  background-color : var(--wintics_yellow);
  color : black;
}

.add-traj:hover,
.add-traj:focus
{
  background-color : var(--wintics_azure_darker_1) !important;
  border : none !important;
  box-shadow: none !important;
  color: black !important;
}
.add-traj:active
{
  background-color : var(--wintics_azure_darker_2) !important;
  border : none !important;
  box-shadow: none !important;
  color: black !important;
}


.add-outflow {
  background-color : var(--wintics_yellow);
  color : black;
}
.add-inflow {
  background-color : var(--wintics_blue);
}
.add-traj {
  background-color : var(--wintics_azure);
  color: black;
}


.zones-blocks {
  /* overflow-y: scroll;
  max-height: 25vh; */
  font-size: 18px;
  color: var(--wintics_font_white);
}

.zoneblock {
  background-color: var(--fill_2);
  /* margin-left: -45px; */
}
.no-padding{
  padding:0 !important;
}
.switch-button{
  margin: 4px 18px 4px 18px;
}
.no-padding-left{
  padding-left:0 !important;
}
.no-margin{
  margin: 0 !important;
}
.zones-blocks .col {
  margin: 0;
  padding: 0;
}
.margin-top-body {
  margin-top : 1.2em;
}


/* .zones-blocks col {
  margin-left: 0;
  padding: 0;
} */


.zones-blocks btn {
  margin-left: 0;
  padding: 0;
  background-color: var(--wintics_yellow);
}
.btnDelete{
  font-size: smaller;
  font-weight: bold;
  padding:1px 6px;
}

.zoneBtn {
  background-color: var(--wintics_yellow);
  color: var(--wintics_font_black);
  border-radius: 50%;
  border: none;
  font-size: small;
  padding:1px 6px;
  margin-right: 2px;
  margin-bottom:2px;

}

.zoneBtn:focus,
.zoneBtn:valid,
.zoneBtn:active,
.zoneBtn:hover {
  color:var(--wintics_font_black) !important;
  background-color: var(--wintics_yellow_darker_1) !important;
  border-color:black !important;
  box-shadow: none !important;
}


.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.open>.dropdown-toggle.btn-outline-primary {
  color:var(--wintics_font_black);
  background-color: var(--wintics_yellow);
  border-color:black;
  box-shadow: none;
}

.outzone-btn-checked {
  background-color: var(--wintics_yellow);
  color: var(--wintics_font_black);
  border-radius: 50%;
  border: none;
}

.outzone-btn-unchecked {
  background-color: var(--wintics_font_black);
  color: var(--wintics_yellow);
  border-radius: 50%;
  border: none;
}

.a_yellow,
.a_yellow:active ,
.a_yellow:visited {
  color: var(--wintics_yellow)!important;
}


.secondary-area-tab{
  padding-top : 13px;
  padding-right : 8px;
  padding-left : 8px;
  padding-bottom : 13px;
  margin-bottom : 30px;
  width : 80px;
  margin-left : 5px;
  margin-right : 5px;
  font-size:15px;
  font-weight: normal;
  text-align: center;
  color: white;
}

.secondary-area-tab-selected, .secondary-area-tab:hover{
  font-weight: bold;
  color: var(--wintics_yellow);
  padding-top : 9px;
  border-top : 4px solid var(--wintics_yellow) !important;
  cursor: pointer;
}


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  color: #b1b1b1;
  visibility: visible;
  opacity: 1;
  padding-left: 4px;
  z-index: inherit;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 3;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}




/* Tooltip container */
.tooltip-no-style {
  position: relative;
  display: inline-block;
  visibility: visible;
  opacity: 1;
  z-index: inherit;
}

/* Tooltip text */
.tooltip-no-style .tooltip-no-style-text {
  visibility: hidden;
  width: 130px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 3;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.5s;
}

/* Tooltip arrow */
.tooltip-no-style .tooltip-no-style-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-no-style:hover .tooltip-no-style-text {
  visibility: visible;
  opacity: 1;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background:  var(--wintics_font_black);;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--wintics_font_gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--wintics_yellow_darker_1);
}

.leaflet-container {  width: 100%;  height: 100%; font-size: 14px !important}
.leaflet-popup-content-wrapper {
  background: var(--fill_3) !important;
  color: white !important;
}
.leaflet-popup-tip {
  background: var(--fill_3) !important;
}

.zone-map{
  margin-top:0px !important;
  height:330px;
  padding: 15px !important;
  border-radius:6px;
  margin-left: 0px !important;
}
.firstGraphContainer {
  /* height: 300px; */
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
}
.secondGraphContainer {
  /* height: 300px; */
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 4;
}
.graphsParentWrapper {
  position: relative;
}